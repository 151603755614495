import apiHelper from "../api_helper";

// target
const TARGET_API = "organisation";

// paths
const API_LIST = "list";
const API_JOIN = "join";

// params

const LOG_TAG = "organisation";
export default {
  getList(onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_LIST);

    window.logger.d(LOG_TAG, "getList", url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  create(data, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API);

    window.logger.d(LOG_TAG, "create", url);
    apiHelper.apiPost(url, data, onResponseCb, onErrorCb);
  },
  delete(data, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, data);

    window.logger.d(LOG_TAG, "delete", url);
    apiHelper.apiDelete(url, onResponseCb, onErrorCb);
  },
  requestJoin(uuid, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, uuid, API_JOIN);

    window.logger.d(LOG_TAG, "Request Join", url);
    apiHelper.apiPost(url, null, onResponseCb, onErrorCb);
  },
  /**
   * Get members from an Organisation.
   *
   * @param {string} uuid                           Company UUID to fetch Members from
   * @param {function(CompanyUser[])} onResponseCb  Calls on Success with response data
   * @param {function(Object)} onErrorCb            Calls on Error
   */
  getMembers(uuid, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, uuid, "user", "list");

    window.logger.d(LOG_TAG, "getOrgMembers", url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  /**
   * Invites a list of users to a given company/org. The users are referenced
   * by an email address and can either be already a part of the system or it
   * will pre-auth a user to create an account before joining the org.
   *
   * @param {string} uuid                       Company UUID to invite users too
   * @param {Object} data                       The request payload
   * @param {string[]} data.userInviteEmailList The list of users to invite
   * @param {function(Object)} onResponseCb     Calls on Success with response data
   * @param {function(Object)} onErrorCb        Calls on Error
   */
  inviteMembers(uuid, data, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, uuid, "invite");

    window.logger.d(LOG_TAG, "inviteOrgMembers", url);
    apiHelper.apiPost(url, data, onResponseCb, onErrorCb);
  },
  /**
   * Accepts a member that has requested to join an organisation.
   *
   * @param {string} org_uuid                 Company UUID
   * @param {string} member_uuid              The User's Member Id who requested to join
   * @param {function(Object)} onResponseCb   Calls on Success with response data
   * @param {function(Object)} onErrorCb      Calls on Error
   */
  acceptMemberJoin(org_uuid, member_uuid, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, org_uuid, "accept", member_uuid);

    window.logger.d(LOG_TAG, "acceptOrgMember", url);
    apiHelper.apiPut(url, null, onResponseCb, onErrorCb);
  },
  /**
   * Rejects a member that has requested to join an organisation.
   *
   * @param {string} org_uuid                 Company UUID
   * @param {string} member_uuid              The User's Member Id who requested to join
   * @param {function(Object)} onResponseCb   Calls on Success with response data
   * @param {function(Object)} onErrorCb      Calls on Error
   */
  rejectMemberJoin(org_uuid, member_uuid, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, org_uuid, "user", member_uuid);

    window.logger.d(LOG_TAG, "rejectOrgMember", url);
    apiHelper.apiDelete(url, onResponseCb, onErrorCb);
  },
  /**
   * Deletes a member that is a part of an organisation.
   *
   * @param {string} org_uuid                 Company UUID
   * @param {string} member_uuid              The User's Member Id who remove from org
   * @param {function(Object)} onResponseCb   Calls on Success with response data
   * @param {function(Object)} onErrorCb      Calls on Error
   */
  DeleteMemberJoin(org_uuid, member_uuid, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, org_uuid, "user", member_uuid);

    window.logger.d(LOG_TAG, "deleteOrgMember", url);
    apiHelper.apiDelete(url, onResponseCb, onErrorCb);
  },
  /**
   * Gets the current dashboard data for the User's Active Organisation.
   *
   * @param {function(Object)} onResponseCb   Calls on Success with response data
   * @param {function(Object)} onErrorCb      Calls on Error
   */
  getDashboard(onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl("dashboard");

    window.logger.d(LOG_TAG, "getDashboard", url);

    // TODO: Make endpoint on Tillered Server
    // apiHelper.apiGet(url, onResponseCb, onErrorCb);

    onResponseCb({
      data: {
        traffic: {
          current_month: "NOV",
          total: [100, 70, 90, 70, 85, 60, 75, 60, 90, 80, 110, 100],
          egress: [80, 120, 105, 110, 95, 105, 90, 100, 80, 95, 70, 120],
          ingress: [60, 80, 65, 130, 80, 105, 90, 130, 70, 115, 60, 130],
        },
        org_stats: {
          users: 12,
          networks: 4,
          nodes: 3,
        },
        notifications: [],
      },
    });
  },
};

/**
 * @typedef {Object} CompanyUser
 * @property {string} uuid - The User's Member Id
 * @property {Boolean} active - Is the Member Link Active
 * @property {Object} user - The User
 * @property {string} user.uuid - The User's Unique Identifier
 * @property {string} user.name - The User's Name
 * @property {Boolean} user.active - Is the User Active
 * @property {string} email - The User's Linked Email
 * @property {string} status - The User's Member Status
 */
