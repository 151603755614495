import apiHelper from "src/webapi/api_helper"

const LOG_TAG = 'nodeinterface';

export default {
  getInterfaces(uuid, onResponseCb, onErrorCb) {
    //let url = "/node/"+uuid+"/interfaces";
    let url = apiHelper.createUrl("node", uuid, "interfaces");
    window.logger.d(LOG_TAG, 'getInterfaces', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  updateInterface(uuid, data, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl("interface", uuid);

    window.logger.d(LOG_TAG, 'updateInterface', url, data);
    apiHelper.apiPut(url, data, onResponseCb, onErrorCb);
  },
}
