<template>
    <div class="d-flex">
        <modal :show.sync="mJoinModal.show"
                body-classes="p-0"
                modal-classes="modal-sm">
            <switch-active-org
                :reset="mJoinModal.reset"
                :on-save="OnSave"
                :on-cancel="OnCancel">
            </switch-active-org>
        </modal>

        <div class="d-none d-lg-flex nav-item p-2 my-auto mr-2" style="background-color: #1a1e34; cursor: pointer;">
            <!-- Active Org Panel -->
            <div class="activeorg " @click="OnClick">
                <span><i class="tim-icons icon-molecule-40" /> {{ getActiveOrg }}</span>
            </div>
        </div>
        <div class="dropdown-item d-flex col-12 d-lg-none nav-item mt-2 justify-content-between" style="cursor: pointer;" @click="OnClick">
            <span class="my-auto user-select-none">Switch Active Org</span>
            
            <!-- Active Org Panel -->
            <div class="d-flex border border-primary rounded px-2 py-1" v-if="getActiveOrg">
                <span class="font-weight-bold user-select-none">Active: </span> 
                <span class="user-select-none"> {{ getActiveOrg }}</span>
            </div>
        </div>
    </div>
</template>
<script>

import { Modal } from 'src/components'
import SwitchActiveOrg from 'src/tillered/forms/SwitchActiveOrg.vue';

export default {
    components: {
        Modal,
        SwitchActiveOrg,
    },
    data() {
        return {
            selected: this.getActiveOrg,
            open: false,
            mJoinModal: {
                reset: 0,
                show: false,
                close() {
                    this.show = false;
                    this.reset = Date.now();
                }
            },
        };
    },
    computed: {
        /**
         * @return {string}
         *      Returns the name of the Current Active Org
         */
        getActiveOrg() {
            return this.$store.getters.userProfile.getCompanyName();
        },

        /**
         * @return {Organisation[]} 
         *      Returns a list of organisations from the store.
         */
        allOrgs() {
            return this.$store.state.organisations.list;
        }
    },
    mounted() {
        window.bloc.account.syncUserOrganisations()
    },
    methods: {
        OnClick() {
            
            if (this.getActiveOrg === "") {
                window.router.push({ name: 'All Organisations' });
                return;
            }

            switch (this.allOrgs.length) {
                case 0:
                    // Redirect to Orgs Page
                    window.router.push({ name: 'All Organisations' });
                    break;
                case 1:
                    // Do nothing, there are no other orgs to switch too
                    break;
                default:
                    // Allow switch
                    this.mJoinModal.show = true;
                    break;
            }
        },
        OnSave(selectedCompany) {
            const vm = this;

            window.webapi.profile.switchUserProfileOrg(
                selectedCompany, 
                (data) => {
                    window.bloc.account.syncUserProfile();
                },
                (error) => {
                    console.log(error);
                }
            )

            vm.mJoinModal.close();
        },
        OnCancel(e) {
            const vm = this;


            vm.mJoinModal.close();
        },
        profile() {
            console.log("profile");
        },
        company() {
            console.log("company");
        }
    }
};

/**
 * @typedef {Object} Contact
 * @property {Boolean} active - Is this an Active Contact
 * @property {string} bankaccountnumber - The Contact's direct Bank Account Number
 * @property {string} buisnessphone - The Contact's Buisness Phone Number
 * @property {string} homephone - The Contact's Home Phone Number
 * @property {string} mobilephone - The Contact's Mobile Phone Number
 * @property {string} email - The Contact's Email
 * @property {string} firstname - The Contact's First Name
 * @property {string} lastname - The Contact's Last Name
 * @property {string} name - The Contact's Full Name
 * @property {string} notes - Notes about the contact
 * @property {Boolean} isbusiness - Is this contact a buisness
 * @property {Boolean} iscustomer - Is this contact a single customer
 * @property {Boolean} isstaff - Is this contact a Tillered staff
 * @property {Boolean} issupplier - Is this contact a supplier
 * @property {Number} number - Not a clue
 * @property {Object|null} physical_address - Is this contact physical address
 * @property {Object|null} postal_address - Is this contact postal address
 * @property {string} reference - Who referenced the Contact
 * @property {string|null} title - The Contact's title
 * @property {string} uuid - The Contact's Unique Id
 * 
 */

/**
 * @typedef {Object} Organisation
 * @property {Boolean} active - The Active State of an Organisation
 * @property {Contact} contact - The Contact for the Organisation
 * @property {string}  name - The Name of the Organisation
 * @property {string}  notes -  Any Notes about the Organisation
 * @property {string}  tax - The TFN of the company
 * @property {string}  userdomain - The Organisation Website
 * @property {string}  uuid - The Unique Id of the Organisation
 * @property {() => string} getContact - Fetches the contact information
 * @property {() => string} getName - Fetches the Organisation Name
 * @property {() => string} getUuid - Fetches the Organisation Uuid
 * @property {() => Boolean} hasContact - Returns true if there is an attached contact
 */


</script>
<style scoped>
.activeorg {
    margin: 0px 5px 0 10px;
    min-width: 300px;
}

</style>
  