import model from "src/model";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      "uuid": "",
      "active": false,
      "name": "",
      "number": 0,
      "iscustomer": 1,
      "isstaff": 0,
      "issupplier": 0,
      "isbusiness": 0,
      "title": {
        "uuid": "",
        "name": ""
      },
      "firstname": "",
      "lastname": "",
      "email": "",
      "postal_address": {
        "uuid": "",
        "address_street_1": "",
        "address_street_2": "",
        "suburb": "",
        "city": "",
        "postcode": "",
        "state_region": "",
        "country_code": ""
      },
      "physical_address": {
        "uuid": "",
        "address_street_1": "",
        "address_street_2": "",
        "suburb": "",
        "city": "",
        "postcode": "",
        "state_region": "",
        "country_code": ""
      },
      "notes": "",
      "homephone": "",
      "mobilephone": "",
      "businessphone": "",
      "bankaccountnumber": "",
      "reference": ""
    };
  }
  object.getUuid = function getUuid() {
    return object.uuid ? object.uuid : '';
  }
  object.getFirstName = function getFirstName() {
    return object.firstname ? object.firstname : '';
  }
  object.getLastName = function getLastName() {
    return object.lastname ? object.lastname : '';
  }
  object.formattedFullName = function formattedFullName() {
    return (object.getFirstName() && object.getLastName()) ? object.getFirstName() + ' ' + object.getLastName() +
      ' (' + object.getUuid() + ')': '';
  }
  return object;
}

