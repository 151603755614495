/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import store from './store';

import axios from 'axios';
import webapi from '@/webapi';
import bloc from '@/bloc';
import logger from '@/util/logger'

// router setup
//import router from './routes/router';
import router from './routes/tilleredrouter.js'
import i18n from './i18n';
import './registerServiceWorker'

import {Select, Option} from 'element-ui'
import {BaseAlert} from 'src/components'

// component setup
Vue.component(BaseAlert.name, BaseAlert)

Vue.use(Select)
Vue.use(Option)

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

// axios global setup
axios.defaults.headers.common.Accept = 'application/json';
axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  // Do something with response error
  const response = error.response
  /*const status = response.status
  logger.w('main', 'axios', status, response.data)
  if (status === 401) {
    //
  }*/
  return Promise.reject(response ? response : {})
})

//Add Store & Router to window so I can get access to it.
window.store = store
window.router = router
window.axios = axios
window.webapi = webapi
window.bloc = bloc
window.logger = logger

/*
// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.config.productionTip = false;


/* eslint-disable no-new */
new Vue({
  store,
  el: '#app',
  render: h => h(App),
  router,
  i18n
});
