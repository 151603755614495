
const state = {
  jwt:""
};

const getters = {
  getToken: (state) => state.jwt
};

const actions = {

};

const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations
};
