import apiHelper from "src/webapi/api_helper"

// target
const TARGET_API = 'kickstart';

// paths
const API_TEMPLATE_LIST = 'template/list';

// params

const LOG_TAG = 'kickstart';
export default {
  getTemplateList(onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_TEMPLATE_LIST);

    window.logger.d(LOG_TAG, 'getTemplateList', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
}
