import apiHelper from "src/webapi/api_helper"

// target
const TARGET_API = 'network';

// paths
const API_ORDER = 'order';
const API_LIST = 'list';

// params
const LOG_TAG = 'network';
export default {
  createNetwork(data, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API);
    window.logger.d(LOG_TAG, 'createNetwork', url);
    apiHelper.apiPost(url, data, onResponseCb, onErrorCb);
  },
  getNetwork(uuid, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, uuid);

    window.logger.d(LOG_TAG, 'getNetwork', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  getList(onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_LIST);
    
    window.logger.d(LOG_TAG, 'getList', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  addNode(networkUuid, nodeUuid, onResponseCb, onErrorCb){
    let url = apiHelper.createUrl(TARGET_API, networkUuid, 'node', nodeUuid);
    window.logger.d(LOG_TAG, 'addNodeToNetwork', url);
    apiHelper.apiPost(url, '', onResponseCb, onErrorCb);
  },
  update(uuid, data, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, uuid);
    
    window.logger.d(LOG_TAG, 'update', url, data);
    apiHelper.apiPut(url, data, onResponseCb, onErrorCb);
  },
  deactivateNetwork(uuid, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, uuid);
  
    window.logger.d(LOG_TAG, 'deactivateNetwork', url);
    apiHelper.apiDelete(url, onResponseCb, onErrorCb);
  },
}
