import model from "src/model";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      "uuid": "",
      "name": "",
      "active": false,
      "tax": "",
      "contact": model.contact(),
      "notes": "",
      "userdomain": ""
    };
  }
  object.getUuid = function getUuid() {
    return object.uuid ? object.uuid : null;
  }
  object.getName = function getName() {
    return object.name ? object.name : null;
  }
  object.getContact = function getContact() {
    return object.contact ? object.contact : null;
  }
  object.hasContact = function hasContact() {
    return object.getContact() ? true : false;
  }
  return object;
}

