import model from "src/model";

/**
 * Filters an array of Network Interfaces to only have the Interface Names that
 * begin with ["T-", "U-", "eth", "en"]
 * 
 * @param {Object[]} data A List of Interfaces to Filter
 * @param {string} data.name The Name of the Interface
 * @returns {Object[]} A List of Filtered Interfaces
 */
const interfaceFilter = (data) => data.filter(nInterface =>
  nInterface.name.startsWith("T-")  || 
  nInterface.name.startsWith("U-")  ||
  nInterface.name.startsWith("eth") ||
  nInterface.name.startsWith("en")
)

/**
 * Checks if a given string is follows a valid uuid format of:
 *      xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
 * 
 * @param {string} uuid An Unique Identifier to validate
 * @returns {Boolean} True if uuid is valid
 */
const validUUID = (uuid) => {
  const uuidPattern = /^[0-9a-f]{8}(-[0-9a-f]{4}){3}-[0-9a-f]{12}$/g;
  return uuidPattern.exec(uuid.toLocaleLowerCase()) !== null;
}

/**
 * 
 * @param {string} email An email to validate
 * @returns {Boolean} True if email is valid
 */
const validateEmail = (email) => {
return String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

/**
 * 
 * @param {string} phone A phone number to validate
 * @returns {Boolean} True if phone is valid
 */
const validatePhone = (phone) => {
return String(phone)
  .replace(/\s+/g, '')
  .toLowerCase()
  .match(
    /^\+?[1-9][0-9]{8,14}$/
  );
};

/**
 * 
 * @param {string} type 
 * @returns {string} A css icon for a given node type
 */
const getNodeIcon = (type) => {
  switch (type) {
    case 'CEN': return 'icon-cloud-download-93';
    case 'TEN': return 'icon-cloud-upload-94';
    case 'FTN': return 'icon-upload';
  }
  return 'icon-alert-circle-exc';
}


const nodeOutOfDate = (node) => {
  let nodeLastSeen = Date.parse(node.lastSeen);
  return Date.now() - nodeLastSeen > 5 * 60 * 1000; // 5 Minutes
}

const getNodeStatus = (node) => {
  let status = 0;

  if (nodeOutOfDate(node)) status++;
  if (!node.authorized) status++;
  if (node.provisioning_state !== 'READY') status++;

  switch(status) {
    case 0: return 'var(--success)';
    case 1: return 'var(--yellow)';
    case 2: return 'var(--warning)';
    default: return 'var(--danger)';
  }
}

const getNodeStatusText = (node) => {
  let response = [];

  if (node.provisioning_state !== 'READY') response.push("Node has not been provisioned");
  if (!node.authorized) response.push("Node has not been authorised");
  if (nodeOutOfDate(node)) response.push("Node is out of date");

  if (response === "") return "Node is OK";
  return response.join('\n');
}

export { 
    interfaceFilter, 
    validUUID,
    validatePhone,
    validateEmail,
    getNodeIcon,
    getNodeStatus,
    nodeOutOfDate,
    getNodeStatusText,
};