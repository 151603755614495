export default {
  apiBaseHeader() {
    const headers = {
      'Authorization': 'Bearer ' + window.store.state.authToken.jwt
    };
    return headers;
  },
  createUrl(...args) {
    let url = process.env.VUE_APP_APIURL;
    args.forEach((arg) => {
      url = `${url}/${arg}`
    })
    return url;
  },
  createParams(...args) {
    let params = '';
    if (args.length > 0 && 0 === args.length % 2) {
      for (let index = 0; index < args.length; index += 2) {
        const key = args[index];
        const value = args[index + 1];
        params  = `${index === 0 ? '?' : ''}${params}${key}=${value}${(args.length > index + 2) ? '&' : ''}`;
      }
    }
    return params;
  },
  apiGet(url, onResponseCb, onErrorCb) {
    const onResponse = function onResponse(response) {
      onResponseCb(response.data);
    };
    const onError = function onError(error) {
      onErrorCb(error.data ? error.data : {})
    };
    window.axios.get(url, {
      headers: this.apiBaseHeader()
    }).then(onResponse).catch(onError);
  },
  apiPost(url, payload, onResponseCb, onErrorCb) {
    const onResponse = function onResponse(response) {
      onResponseCb(response.data);
    };
    const onError = function onError(error) {
      onErrorCb(error.data ? error.data : {})
    };
    window.axios.post(url, payload, {
      headers: this.apiBaseHeader()
    }).then(onResponse).catch(onError);
  },
  apiPut(url, payload, onResponseCb, onErrorCb) {
    const onResponse = function onResponse(response) {
      onResponseCb(response.data);
    };
    const onError = function onError(error) {
      onErrorCb(error.data ? error.data : {})
    };
    window.axios.put(url, payload, {
      headers: this.apiBaseHeader()
    }).then(onResponse).catch(onError);
  },
  apiDelete(url, onResponseCb, onErrorCb) {
    const onResponse = function onResponse(response) {
      onResponseCb(response.data);
    };
    const onError = function onError(error) {
      onErrorCb(error.data ? error.data : {})
    };
    window.axios.delete(url, {
      headers: this.apiBaseHeader()
    }).then(onResponse).catch(onError);
  },
};

