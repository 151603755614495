import model from "src/model"

const state = {
  templateList: []
};

const getters = {
};

const actions = {
};

const mutations = {
  setTemplateList: (state, data) => (state.templateList = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
  components: {
  }
};
