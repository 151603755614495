import model from "src/model";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      "apiVersion": "1.0",
      "data": [
        {
          "uuid": "737b364e-504a-41ff-9f71-7d8c6db49108",
          "active": true,
          "type": "",
          "name": "DarylHome",
          "serialnumber": "10000000c2ea0dae",
          "public_ipv4": "203.211.73.96",
          "public_ipv6": "",
          "node_vpn_ipv4": "172.28.28.130",
          "node_vpn_ipv6": "fe80::2cad:5fff:fef6:a07f",
          "fqdn": "737b364e-504a-41ff-9f71-7d8c6db49108.tillered.net",
          "template": "fedorapi1.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "aarch64",
          "kernel": "5.16.9-200.fc35.aarch64",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "10a7bb91-789c-49f4-bb5e-251539ea2f0c",
          "active": true,
          "type": "",
          "name": "DaveyPi1",
          "serialnumber": "100000000c190ed4",
          "public_ipv4": "203.211.80.6",
          "public_ipv6": "",
          "node_vpn_ipv4": "172.28.238.144",
          "node_vpn_ipv6": "fe80::2ccd:5ff:feb9:7558",
          "fqdn": "10a7bb91-789c-49f4-bb5e-251539ea2f0c.tillered.net",
          "template": "rpi4b.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "",
          "kernel": "",
          "authorized": 0,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "ef50509c-a90e-4cef-b7ff-d136eb293ba9",
          "active": true,
          "type": "",
          "name": "daveyhome-tilleredvm",
          "serialnumber": "VMware-56 4d 66 aa 4f 33 7a 56-6b 5c 46 03 fa 7b b6 4d",
          "public_ipv4": "203.211.80.9",
          "public_ipv6": "",
          "node_vpn_ipv4": "172.28.37.163",
          "node_vpn_ipv6": "fe80::2c0d:3aff:fec7:d9ca",
          "fqdn": "ef50509c-a90e-4cef-b7ff-d136eb293ba9.tillered.net",
          "template": "vmware.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "x86_64",
          "kernel": "5.16.16-200.fc35.x86_64",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "de907cd4-5937-4d72-94a5-6dc3936e5700",
          "active": true,
          "type": "",
          "name": "LachlanPi4",
          "serialnumber": "0000E45F0167FB87",
          "public_ipv4": "121.200.6.33",
          "public_ipv6": "",
          "node_vpn_ipv4": "172.28.29.121",
          "node_vpn_ipv6": "fe80::2c01:b9ff:fe33:4927",
          "fqdn": "de907cd4-5937-4d72-94a5-6dc3936e5700.tillered.net",
          "template": "rpi4b.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "aarch64",
          "kernel": "",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "cd25c68f-d4a9-4ac4-b754-c6504f13185b",
          "active": true,
          "type": "",
          "name": "Davey-Vmware-Mac",
          "serialnumber": "EDFC74F86A164D56",
          "public_ipv4": "192.168.0.145",
          "public_ipv6": "",
          "node_vpn_ipv4": "172.28.184.42",
          "node_vpn_ipv6": "fe80::2cc2:1bff:fe78:c935",
          "fqdn": "cd25c68f-d4a9-4ac4-b754-c6504f13185b.tillered.net",
          "template": "vmware_aarch64.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "aarch64",
          "kernel": "",
          "authorized": 0,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "61c0bcff-0c46-4445-8608-8221ffbdc768",
          "active": true,
          "type": "",
          "name": "Colin Digicel",
          "serialnumber": "0000E45F0159DAD4",
          "public_ipv4": "",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "",
          "fqdn": "61c0bcff-0c46-4445-8608-8221ffbdc768.tillered.net",
          "template": "rpi4b.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "",
          "kernel": "",
          "authorized": 0,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "b9f8775a-3eac-4775-aba7-6b5cfe5d2575",
          "active": true,
          "type": "",
          "name": "aws-us-daveytest",
          "serialnumber": "ec2494d9-3bc5-3ac6-d521-b13ccb596a5c",
          "public_ipv4": "34.221.39.152",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "fe80::2c3e:47ff:fe91:f656",
          "fqdn": "b9f8775a-3eac-4775-aba7-6b5cfe5d2575.tillered.net",
          "template": "ec2.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "aarch64",
          "kernel": "",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "391a22df-6ac7-4d23-9e9d-023824aec59f",
          "active": true,
          "type": "",
          "name": "colin_stone-sydney",
          "serialnumber": "ec2d5c01-52f8-8056-2ec7-c98d473923ee",
          "public_ipv4": "54.206.177.97",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "fe80::2cfe:cfff:fea2:45e2",
          "fqdn": "391a22df-6ac7-4d23-9e9d-023824aec59f.tillered.net",
          "template": "ec2.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "aarch64",
          "kernel": "5.10.96-90.460.amzn2.aarch64",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "18150862-7165-419f-b1e1-98637ac56c31",
          "active": true,
          "type": "",
          "name": "aws-northcalifornia",
          "serialnumber": "ec2f35fb-566a-ac45-85c8-b7cd3a1413a8",
          "public_ipv4": "13.56.79.155",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "fe80::2c37:fbff:fe7d:a6b7",
          "fqdn": "18150862-7165-419f-b1e1-98637ac56c31.tillered.net",
          "template": "ec2.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "aarch64",
          "kernel": "",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "4b5ba73a-aadc-4008-9fd4-943a6e3c72cb",
          "active": true,
          "type": "",
          "name": "moxion-origon-test",
          "serialnumber": "ec289d24-5231-d5f8-2a49-144d661eb228",
          "public_ipv4": "35.84.96.186",
          "public_ipv6": "",
          "node_vpn_ipv4": "172.29.1.1",
          "node_vpn_ipv6": "fe80::8070:e6ff:feb6:68ca",
          "fqdn": "4b5ba73a-aadc-4008-9fd4-943a6e3c72cb.tillered.net",
          "template": "ec2.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "aarch64",
          "kernel": "",
          "nodeGroups": [
            "d250e3bd-4b12-493d-97b9-401db197abfc"
          ],
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "9575bacc-3464-45d7-b0b0-9f2f7417e36d",
          "active": true,
          "type": "",
          "name": "london_exit_node1",
          "serialnumber": "ec2630dc-bbff-1704-8410-eff810cfda04",
          "public_ipv4": "18.130.129.232",
          "public_ipv6": "",
          "node_vpn_ipv4": "172.28.3.124",
          "node_vpn_ipv6": "fe80::2c42:e5ff:fe04:6cf2",
          "fqdn": "9575bacc-3464-45d7-b0b0-9f2f7417e36d.tillered.net",
          "template": "",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "",
          "kernel": "",
          "nodeGroups": [
            "d250e3bd-4b12-493d-97b9-401db197abfc"
          ],
          "authorized": 0,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "923e5770-e084-44a6-aa9a-b312d88ecc9e",
          "active": true,
          "type": "",
          "name": "Daryl-Vmware",
          "serialnumber": "VMware-56 4d e8 17 21 75 f2 ea-45 37 5f c0 0d 65 8e 4e",
          "public_ipv4": "203.211.73.96",
          "public_ipv6": "",
          "node_vpn_ipv4": "172.28.76.135",
          "node_vpn_ipv6": "fe80::2c02:a7ff:fec1:f82e",
          "fqdn": "923e5770-e084-44a6-aa9a-b312d88ecc9e.tillered.net",
          "template": "vmware.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "x86_64",
          "kernel": "5.16.11-200.fc35.x86_64\n",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "58756c0b-d842-40d6-9935-6b8703f80832",
          "active": true,
          "type": "",
          "name": "DaveyTest-Vmware",
          "serialnumber": "VMware-56 4d 53 93 db 63 86 65-60 13 f1 c1 4d e2 24 40",
          "public_ipv4": "203.211.80.6",
          "public_ipv6": "",
          "node_vpn_ipv4": "172.28.40.166",
          "node_vpn_ipv6": "fe80::2cfb:4aff:fe3d:72fe",
          "fqdn": "58756c0b-d842-40d6-9935-6b8703f80832.tillered.net",
          "template": "vmware.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "",
          "kernel": "",
          "authorized": 0,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "68bf643c-b0bc-48cd-af80-120ea93c7d48",
          "active": true,
          "type": "",
          "name": "autocreation-vmware-1647479349",
          "serialnumber": "VMware-56 4d c9 9c 6b b5 d0 32-c2 37 d3 15 e9 67 58 8c",
          "public_ipv4": "203.211.80.6",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "",
          "fqdn": "68bf643c-b0bc-48cd-af80-120ea93c7d48.tillered.net",
          "template": "vmware.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "",
          "kernel": "",
          "authorized": 0,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "9251e74a-2be7-42c0-866d-459bf3cfe432",
          "active": true,
          "type": "",
          "name": "autocreation-vmware-1647483724",
          "serialnumber": "8139-8395-3496-1558-1273-2465-11",
          "public_ipv4": "",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "",
          "fqdn": "9251e74a-2be7-42c0-866d-459bf3cfe432.tillered.net",
          "template": "vmware.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "",
          "kernel": "",
          "authorized": 0,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "990cdb8a-1402-4bce-8dba-b240eb5a8d57",
          "active": true,
          "type": "",
          "name": "autocreation-vmware-1647555302",
          "serialnumber": "A349395X2113720",
          "public_ipv4": "103.13.119.110",
          "public_ipv6": "",
          "node_vpn_ipv4": "172.28.66.61",
          "node_vpn_ipv6": "fe80::2c4c:fdff:fe31:6670",
          "fqdn": "990cdb8a-1402-4bce-8dba-b240eb5a8d57.tillered.net",
          "template": "vmware.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "x86_64",
          "kernel": "5.16.14-200.fc35.x86_64\n",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "2be4b941-c448-4079-9be1-a4b2e29926d8",
          "active": true,
          "type": "",
          "name": "Davey DTT Test Node 1",
          "serialnumber": "VMware-56 4d 74 cb 0d e9 f0 66-99 c2 0a 49 4f ef d4 4f",
          "public_ipv4": "203.211.80.5",
          "public_ipv6": "",
          "node_vpn_ipv4": "172.28.211.55",
          "node_vpn_ipv6": "fe80::2c32:b7ff:fe33:9164",
          "fqdn": "2be4b941-c448-4079-9be1-a4b2e29926d8.tillered.net",
          "template": "vmware.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "x86_64",
          "kernel": "5.16.14-200.fc35.x86_64\n",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "7a9a0630-af82-41d2-bfa6-1feaba53b12d",
          "active": true,
          "type": "",
          "name": "autocreation-vmware-1647921822",
          "serialnumber": "3275-1385-3423-1244-7480-5135-13",
          "public_ipv4": "203.211.80.6",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "",
          "fqdn": "7a9a0630-af82-41d2-bfa6-1feaba53b12d.tillered.net",
          "template": "vmware.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "x86_64",
          "kernel": "5.16.15-201.fc35.x86_64\n",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "3f08504b-2424-4063-a0d3-c8ea0e42b49a",
          "active": true,
          "type": "",
          "name": "autocreation-vmware-1647928636",
          "serialnumber": "8921-9316-3693-6126-2666-5802-16",
          "public_ipv4": "203.211.80.6",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "",
          "fqdn": "3f08504b-2424-4063-a0d3-c8ea0e42b49a.tillered.net",
          "template": "vmware.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "x86_64",
          "kernel": "5.16.15-201.fc35.x86_64\n",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "90b08b28-97ab-4fe2-91bf-66c4e4961b87",
          "active": true,
          "type": "",
          "name": "autocreation-vm-1647930454",
          "serialnumber": "4651-9221-0336-5463-8719-8771-77",
          "public_ipv4": "203.211.73.96",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "",
          "fqdn": "90b08b28-97ab-4fe2-91bf-66c4e4961b87.tillered.net",
          "template": "hyperv.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "x86_64",
          "kernel": "5.16.15-201.fc35.x86_64\n",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "9d637782-3dd7-45cc-8010-b0d78b558f97",
          "active": true,
          "type": "",
          "name": "autocreation-vm-1647931902",
          "serialnumber": "2005-7664-9492-0919-0967-9185-28",
          "public_ipv4": "203.211.73.96",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "",
          "fqdn": "9d637782-3dd7-45cc-8010-b0d78b558f97.tillered.net",
          "template": "hyperv.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "x86_64",
          "kernel": "5.16.15-201.fc35.x86_64\n",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "19734e56-9197-4ddb-beb3-ae33b5709da5",
          "active": true,
          "type": "",
          "name": "autocreation-vm-1648580516",
          "serialnumber": "ec2deb51-4f94-163a-bd77-04525bb3d682",
          "public_ipv4": "54.214.136.184",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "",
          "fqdn": "19734e56-9197-4ddb-beb3-ae33b5709da5.tillered.net",
          "template": "ec2.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "aarch64",
          "kernel": "5.16.17-200.fc35.aarch64",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "ddc90f7f-e055-47e7-85ed-f5d8d494799a",
          "active": true,
          "type": "",
          "name": "autocreation-vm-1648602091",
          "serialnumber": "VMware-56 4d 3b e7 20 bf f2 7d-fa d3 34 89 ef 29 46 e7",
          "public_ipv4": "203.211.73.96",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "",
          "fqdn": "ddc90f7f-e055-47e7-85ed-f5d8d494799a.tillered.net",
          "template": "vmware.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "x86_64",
          "kernel": "5.16.17-200.fc35.x86_64",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "f65adb38-1c33-48e1-b0cd-4067c9e9b0ad",
          "active": true,
          "type": "",
          "name": "tillered-test-0002",
          "serialnumber": "VMware-56 4d a2 d7 d0 90 29 74-29 4a 16 11 c6 08 81 6d",
          "public_ipv4": "203.211.73.96",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "",
          "fqdn": "f65adb38-1c33-48e1-b0cd-4067c9e9b0ad.tillered.net",
          "template": "vmware.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "x86_64",
          "kernel": "5.16.18-200.fc35.x86_64",
          "nodeGroups": [
            "d250e3bd-4b12-493d-97b9-401db197abfc"
          ],
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "a2af3974-0d98-430f-9ef6-195432ea5850",
          "active": true,
          "type": "",
          "name": "autocreation-vm-1648673591",
          "serialnumber": "VMware-56 4d 5b 8f 9b 56 4c 79-22 ee 8a 8f 70 40 32 31",
          "public_ipv4": "203.211.80.9",
          "public_ipv6": "",
          "node_vpn_ipv4": "172.28.245.61",
          "node_vpn_ipv6": "fe80::2c19:49ff:fe54:b1a3",
          "fqdn": "a2af3974-0d98-430f-9ef6-195432ea5850.tillered.net",
          "template": "vmware.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "x86_64",
          "kernel": "5.16.18-200.fc35.x86_64",
          "authorized": 1,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "a107c3b6-3549-404b-aac2-a7b39c3bf482",
          "active": true,
          "type": "",
          "name": "autocreation-vm-1648748482",
          "serialnumber": "ec29a7ef-91b3-00d0-0965-ce0ea1069e6b",
          "public_ipv4": "3.104.117.43",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "",
          "fqdn": "a107c3b6-3549-404b-aac2-a7b39c3bf482.tillered.net",
          "template": "ec2.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "aarch64",
          "kernel": "5.16.18-200.fc35.aarch64",
          "authorized": 0,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "02c18e9c-a62f-4cad-a0f9-a89da95b6046",
          "active": true,
          "type": "",
          "name": "autocreation-vm-1648756866",
          "serialnumber": "ec22065c-4504-1de9-9959-c0c962d85062",
          "public_ipv4": "",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "",
          "fqdn": "02c18e9c-a62f-4cad-a0f9-a89da95b6046.tillered.net",
          "template": "ec2.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "",
          "kernel": "",
          "authorized": 0,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        },
        {
          "uuid": "7e8309f9-095c-4244-95c4-787fa060d931",
          "active": true,
          "type": "",
          "name": "autocreation-vm-1648761568",
          "serialnumber": "ec286742-d49e-c4d2-8f7f-6f6d924766a8",
          "public_ipv4": "",
          "public_ipv6": "",
          "node_vpn_ipv4": "",
          "node_vpn_ipv6": "",
          "fqdn": "7e8309f9-095c-4244-95c4-787fa060d931.tillered.net",
          "template": "ec2.template",
          "organisation": {
            "uuid": "f83a90ad-518b-470a-bebe-7db138fb08f6",
            "name": "Tillered Limited",
            "active": true,
            "tax": "",
            "contact": {
              "uuid": "086b58bc-9a3c-44d7-b7ef-3d497453fe42",
              "active": true,
              "name": "Daryl Blake",
              "number": 17847887,
              "iscustomer": 1,
              "isstaff": 0,
              "issupplier": 0,
              "isbusiness": 0,
              "title": null,
              "firstname": "Daryl",
              "lastname": "Blake",
              "email": "",
              "postal_address": null,
              "physical_address": null,
              "notes": "this is a test note.",
              "homephone": "",
              "mobilephone": "",
              "businessphone": "",
              "bankaccountnumber": "12-3042-0330204-00",
              "reference": "test reference"
            },
            "notes": "Tillered Limited. Tillered is an Over-the-Top Internet Services company.",
            "userdomain": "tillered.com"
          },
          "lastSeen": "0001-01-01T00:00:00Z",
          "architecture": "",
          "kernel": "",
          "authorized": 0,
          "provisioning_state": "",
          "cloud_instance_id": "",
          "cloud_provider": "",
          "disk_free_percentage": 0
        }
      ]
    }
  }
  object.getData = function getData() {
    return object.data ? object.data : [];
  }
  object.hasList = function hasList() {
    return object.getData().length > 0 ? true : false;
  }
  object.getList = function getList() {
    return object.getData().map((data) => model.node(data));
  }
  return object;
}

