import Vue from 'vue';
import Router from 'vue-router';
import DashboardLayout from './../tillered/layout/SampleLayout.vue';
import OrganisationLayout from './../tillered/layout/OrganisationLayout.vue';
import Starter from './../pages/Starter/SamplePage.vue';

//For help see: https://auth0.com/docs/quickstart/spa/vuejs/01-login
import {Auth0Plugin, getInstance, useAuth0} from "../auth";
import { authGuard } from "../auth/authGuard";
const Login = () => import('src/tillered/pages/Home.vue');

const Dashboard = () => import('src/tillered/pages/Dashboard.vue')
//const UserDashboard = () => import('src/tillered/pages/Dashboard.vue')
// TableList pages
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ 'src/pages/Tables/PaginatedTables.vue');

const VectorMaps = () => import(/* webpackChunkName: "extra" */ 'src/pages/Maps/VectorMaps.vue');
const Profile = () => import('src/tillered/pages/Profile.vue');
const Organisations = () => import('src/tillered/pages/Organisations.vue');
const NewOrg = () => import('src/tillered/pages/organisation/CreateOrganisation.vue');
const ManageOrg = () => import('src/tillered/pages/OrganisationManagement.vue');
const Nodes = () => import('src/tillered/pages/Nodes.vue');
const Networks = () => import('src/tillered/pages/Networks.vue');
const NodeDetails = () => import('src/tillered/pages/nodes/NodeDetails.vue');
const VerifyEmail = () => import('src/tillered/pages/VerifyEmail.vue');
const NetworksDetails = () => import('src/tillered/pages/NetworkEdit.vue');

Vue.use(Router);

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'root',
      component: Login,
    },
    {
      path: '/verify',
      name: 'Verify',
      beforeEnter: authGuard,
      component: VerifyEmail,
    },
    {
      path: '/home',
      name: 'Tillered Hub',
      component: DashboardLayout,
      redirect: '/home/dashboard',
      meta: {
        auth: true
      },
      beforeEnter: authGuard,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard and Overview',
          components: { default: Dashboard }
        },
        // {
        //   path: 'cloudlocations',
        //   name: 'Cloud Locations',
        //   components: { default: VectorMaps }
        // },
        // {
        //   path: 'trafficmanagement',
        //   name: 'Traffic Management',
        //   components: { default: PaginatedTables }
        // },
        // {
        //   path: 'subscriptions',
        //   name: 'Subscriptions',
        //   components: { default: PaginatedTables }
        // },
        {
          path: 'profile',
          name: 'Profile',
          components: { default: Profile }
        },
        {
          path: 'organisations',
          component: OrganisationLayout,
          redirect: 'organisations/all',
          name: 'Organisations',
          children: [
            {
              path: 'all',
              name: 'All Organisations',
              components: { default: Organisations }
            },
            {
              path: 'manage',
              name: 'Manage Active Org',
              components: { default: ManageOrg }
            },
            {
              path: 'new',
              name: 'Create a New Org',
              components: { default: NewOrg }
            }
          ],
        },
        {
          path: 'nodes',
          name: 'Nodes',
          components: { default: Nodes }
        },
        {
          path: 'networks',
          name: 'Networks',
          components: { default: Networks }
        },
        {
          path: 'networks/:uuid',
          name: 'Network Details',
          components: { default: NetworksDetails }
        },
        {
          path: 'nodes/:uuid',
          name: 'Node Details',
          components: { default: NodeDetails },
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        auth: false
      },
    }
  ],
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

/*
let userinfo = JSON.parse(JSON.stringify(getInstance()._data));
let userinfo2 = JSON.parse(JSON.stringify(getInstance()._data));
router.beforeEach((to, from, next) => {
  if (to.matched.some(m => m.meta.auth)) {

    // console.log("user: "+loginData.user.email)
    // console.log("isAuthenticated value: "+ (window.isAuthenticated?"true":"false" ))

    if (!useAuth0.getTokenSilently) {
      console.log(userinfo)
      console.log(userinfo2)
      console.log('we are not authenticated')
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else {
    if (null==to.name || to.name.length == 0) {
      next({
        path: '/home',
      })
    } else {
      next()
    }
  }
})
*/

export default router
