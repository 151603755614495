import { getInstance } from "./index";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {

    // If the user is authenticated and email verified, continue with the route
    if (authService.isAuthenticated) {
      console.log("authGuard: user is authenticated");
      
      if (!authService.user.email_verified && to.path != '/verify') {
        return next('/verify')
      } else if (authService.user.email_verified && to.path == '/verify') {
        return next('/home')
      }

      return next();
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };
  

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};
