import apiHelper from "src/webapi/api_helper"

// target
const TARGET_API = 'node';

// paths

const API_ORDER = 'order';
const API_LIST = 'list';
const API_ORDER_VALIDATE = 'ordervalidate'

// params

const LOG_TAG = 'node';
export default {
  createOrder(data, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_ORDER);

    window.logger.d(LOG_TAG, 'createOrder', url);
    apiHelper.apiPost(url, data, onResponseCb, onErrorCb);
  },
  getList(onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_LIST);

    window.logger.d(LOG_TAG, 'getList', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  getNode(uuid, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, uuid);

    window.logger.d(LOG_TAG, 'getNode', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  update(uuid, data, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, uuid);

    window.logger.d(LOG_TAG, 'update', url, data);
    apiHelper.apiPut(url, data, onResponseCb, onErrorCb);
  },
  validateRemote(data, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_ORDER_VALIDATE);
    window.logger.d(LOG_TAG, API_ORDER_VALIDATE, url, data)
    apiHelper.apiPost(url, data, onResponseCb, onErrorCb)
  },
  deactivateNode(uuid, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, "cancel", uuid);

    window.logger.d(LOG_TAG, 'deleteNode', url);
    apiHelper.apiDelete(url, onResponseCb, onErrorCb);
  }
}
