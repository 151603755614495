import userProfile from "src/model/user_profile";
import organisations from "src/model/organisations";
import organisation from "src/model/organisation";
import contacts from "src/model/contacts";
import contact from "src/model/contact";
import nodes from "src/model/nodes";
import nodeNetworkInterface from "src/model/networkinterface";
import nodeNetworkInterfaces from "src/model/networkinterfaces";
import node from "src/model/node";
import nodesMock from "src/model/nodes_mock";
import error from "src/model/error";
import network from "src/model/network";
import networks from "src/model/networks";
import {
  interfaceFilter,
  validUUID,
  validatePhone,
  validateEmail,
  getNodeIcon,
  getNodeStatus,
  nodeOutOfDate,
  getNodeStatusText,
} from "src/model/functions";

export default {
  userProfile,
  organisations,
  organisation,
  contacts,
  contact,
  nodes,
  node,
  nodesMock,
  error,
  nodeNetworkInterface,
  nodeNetworkInterfaces,
  network,
  networks,

  // Functions
  interfaceFilter,
  validUUID,
  validatePhone,
  validateEmail,
  getNodeIcon,
  getNodeStatus,
  nodeOutOfDate,
  getNodeStatusText,
};
