const state = {
  list: []
};

const getters = {
};

const actions = {
};

const mutations = {
  setNodes: (state, data) => (state.list = data),
  setNode: (state, data, index) => (state.list[index] = data)
};

export default {
  state,
  getters,
  actions,
  mutations
};
