<template>
  <base-nav v-model="showMenu" class="navbar-absolute top-navbar" type="white" :transparent="true">
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="#">{{ routeName }}</a>

    </div>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <!-- Active Org Panel -->
      <active-org></active-org>

      <li class="nav-link d-lg-none">
        <span href="#" @click="profile" class="nav-item dropdown-item">My Profile</span>
      </li>
      <li class="nav-link  d-lg-none">
        <span href="#" @click="company" class="nav-item dropdown-item">Organisations</span>
      </li>
      <li class="nav-link  d-lg-none" v-if="activeOrg">
        <span href="#" @click="managecompany" class="nav-item dropdown-item">Manage Active Org</span>
      </li>
      <div class="dropdown-divider"></div>
      <li class="nav-link  d-lg-none">
        <span href="#" class="nav-item dropdown-item" @click="logout">Log out</span>
      </li>

      <!-- Profile Icon Drop Down Menu -->
      <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" class="nav-item d-none d-lg-block d-xl-block"
        title-classes="nav-link" menu-classes="dropdown-navbar">
        <template slot="title">
          <div class="photo"><img :src="this.$auth.user.picture ? this.$auth.user.picture : 'default-avatar.png'" /></div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none">Log out</p>
        </template>
        <li class="nav-link">
          <a href="#" @click="profile" class="nav-item dropdown-item">My Profile</a>
        </li>
        <li class="nav-link">
          <a href="#" @click="company" class="nav-item dropdown-item">Organisations</a>
        </li>
        <li class="nav-link" v-if="activeOrg">
          <a href="#" @click="managecompany" class="nav-item dropdown-item">Manage Active Org</a>
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item" @click="logout">Log out</a>
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import ActiveOrg from '../components/ActiveOrg.vue';

export default {
  components: {
    SidebarToggleButton,
    BaseNav,
    ActiveOrg
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    storeUsers() {
      return this.$store.state.users;
    },
    storeUsersProfile() {
      return this.storeUsers.userProfile;
    },
    activeOrg() {
      return this.storeUsersProfile.getCompany();
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ''
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin + "/login"
      });
    },
    profile() {
      this.$router.push({ path: '/home/profile' })
    },
    company() {
      this.$router.push({ path: '/home/organisations' })
    },
    managecompany() {
      this.$router.push({ path: '/home/organisations/manage' })
    }
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}

.activeorg {
  margin: 0px 5px 0 10px;
  min-width: 300px;
}
</style>
