import model from "src/model";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      "uuid": "",
      "node": model.node(),
      "name": "",
      "mac": "",
      "ip4Full": "",
      "ip6Full": "",
      "usekcp": 0,
      "sockBuffer": 0,
      "rWindowSize": 0,
      "sWindowSize": 0,
      "transparent": 0,
      "buildToPrivate": 0
    };
  }
  object.getUuid = function getUuid() {
    return object.uuid ? object.uuid : null;
  }
  object.getName = function getName() {
    return object.name ? object.name : '---';
  }
  return object;
}

