import Vuex from 'vuex';
import Vue from 'vue';

// Modules
import global from './modules/global';
import users from './modules/users';
import routers from './modules/routers'
import authToken from './modules/authtoken'
import alerts from './modules/alerts'
import organisations from "./modules/organisations";
import nodes from "./modules/nodes";
import networkinterface from "./modules/networkinterface"
import networks from "./modules/network"

// Load Vuex
Vue.use(Vuex)

// Create the store
export default new Vuex.Store({
  modules: {
    global,
    users,
    authToken,
    alerts,
    routers,
    organisations,
    nodes,
    networks,
  },
});
