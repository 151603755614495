import model from "src/model";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      "uuid": "",
      "active": null,
      "organisation": model.organisation(),
      "name": "",
      "nodeCount": 0,
      "vpnid": "",
      "nodes": [],
    };
  }
  object.getUuid = function getUuid() {
    return object.uuid ? object.uuid : null;
  }
  object.getName = function getName() {
    return object.name ? object.name : '';
  }
  return object;
}

