import axios from "axios";

const state = {
  routers: [
  ]
};

const getters = {
  allRouters: (state) => state.routers,
};


const actions = {
  async fetchAllRouters({ commit }) {
    // Use Axios to make a call to the API
    console.log('Getting Data from source')

    //debugger
    const { data } = await axios.get(process.env.VUE_APP_APIURL + "/node/list", {
       headers: {
         Authorization: `Bearer ${window.store.state.authToken.jwt}`    // send the access token through the 'Authorization' header
       }
     });
    commit('setRouters',  data.data );

  },
  /*
   updateRouter(context, router) {
    axios.put(process.env.VUE_APP_APIURL + "/node", router, {
      headers: {
        Authorization: `Bearer ${window.store.state.authToken.jwt}`
      }
    }).then(result => {
      if (result.status == 200) {
        window.store.dispatch('addSuccess', { title:'Success',message:'Node saved Successfully', timeout:10000 })
      }
    }).catch(function (error) {
      window.store.dispatch('addError', { title:'Error',message: error.response.data.error.message, timeout:10000 })
    });
  }
  */
};

const mutations = {
  setRouters: (state, allRouterData) => (state.routers = allRouterData),
  setRouter: (state, routerData, index) => (state.routers[index] = routerData)
};

export default {
  state,
  getters,
  actions,
  mutations
};
