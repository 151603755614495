import datetimeHelper from "src/util/datetimeHelper";
import model from "src/model";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      "uuid": "",
      "active": false,
      "type": "",
      "name": "",
      "serialnumber": "",
      "public_ipv4": "",
      "public_ipv6": "",
      "node_vpn_ipv4": "",
      "node_vpn_ipv6": "",
      "fqdn": "",
      "template": "",
      "organisation": model.organisation(),
      "lastSeen": "",
      "architecture": "",
      "kernel": "",
      "authorized": 1,
      "provisioning_state": "",
      "cloud_instance_id": "",
      "cloud_provider": "",
      "disk_free_percentage": 0,
    };
  }
  object.getUuid = function getUuid() {
    return object.uuid ? object.uuid : null;
  }
  object.getName = function getName() {
    return object.name ? object.name : '---';
  }
  object.formattedLastSeen = function formattedLastSeen() {
    return object.lastSeen ? datetimeHelper.formattedTimeDate(object.lastSeen) : '---';
  }
  object.diskUsagePercentage = function diskUsagePercentage() {
    return (100-object.disk_free_percentage).toFixed(2)
  }
  object.getProvisioningState = function getProvisioningState() {
    return object.provisioning_state ? object.provisioning_state : '---';
  }
  return object;
}

