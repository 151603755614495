export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      "code": 0,
      "message": '',
    };
  }
  object.getCode = function getCode() {
    return object.code ? object.code : 0;
  }
  object.getMessage = function getMessage() {
    return object.message ? object.message : 'Unknown error';
  }
  object.formattedErrorMessage = function formattedErrorMessage() {
    return '[' + object.getCode() + ']: ' + object.getMessage();
  }
  return object;
}

