import moment from "moment"

export default {
  formattedTimeDate(value, ignoreTime) {
    if (ignoreTime) {
      return moment(value).format('DD.MMM.YY');
    } else {
      return moment(value).format('hh:mm a, DD.MMM.YY');
    }
  },

};
