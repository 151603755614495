<template>
    <card
      type="secondary"
      class="border-0 mb-0">
      <form @submit.prevent="onJoinOrganisation">
  
        <h6 slot="header" class="modal-title" id="modal-title-new">Switch Active Organisation</h6>
        <div class="mt-2"></div>
        <div class="whitetext">Select one of the following to switch your active organisation:</div>
        <div class="mt-2"></div>
        <div class="px-2">
            <div v-for="org in allOrgs" 
                :key="org.uuid"
                :class="[
                  'card',
                  'mb-2',
                  'border',
                  isHovering === org.uuid? 'border-primary' : 'border-default']"
                style="cursor: pointer"
                @mouseover="isHovering = org.uuid"  
                @mouseout="isHovering = ''"
                @click="() => onOrgSwitch(org.uuid)">
              <div class="row mx-2">
                <div class="card-body col-9 user-select-none">
                    {{ org.getName() }}
                </div>
                <div class="card-body col-3 fw-semibold text-primary user-select-none" style="text-align:right">
                  {{ (getActiveOrg ? getActiveOrg.uuid : '') === org.uuid ? "Active" : ""}}
                </div>
              </div>
            </div>
        </div>
      </form>
    </card>
  </template>
  <script>
  
  import model from "../../model";

  const LOG_TAG = 'switch_organisation';
  
  export default {
    props: {
      onSave: {
        type: Function,
        required: true,
      },
      onCancel: {
        type: Function,
        required: true,
      },
      reset: {
        type: Number,
        default: function() {
          return 0;
        }
      }
    },
    data() {
      return {
        isHovering: '',
        mOrganisation: {
          uuid: '',
          reset() {
            this.uuid = '';
          },
          export() {
            let payload = {
              "uuid": this.uuid,
            };
            return payload;
          }
        }
      };
    },
    computed: {

      /**
       * @return {string}
       *      Returns the name of the Current Active Org
       */
      getActiveOrg() {
        return this.$store.getters.userProfile.getCompany();
      },

      /**
       * @return {Organisation[]} 
       *      Returns a list of organisations from the store.
       */
      allOrgs() {
        return this.$store.state.organisations.list;
      },


      storeState() {
        return this.$store.state;
      },
      storeUsers() {
        return this.$store.state.users;
      },
      storeUserContacts() {
        return this.storeUsers.contacts;
      },
      isValid() {
        return model.validUUID(this.mOrganisation.uuid);
      },
    },
    watch: {
      reset: function(value) {
        const vm = this;
        window.logger.d(LOG_TAG, 'watch:reset', value);
        vm.mOrganisation.reset();
      },
    },
    methods: {
      onOrgSwitch(orgUuid) {
        const vm = this;
        vm.onSave(orgUuid);
      }
    },
  };
  </script>
  <style></style>
  