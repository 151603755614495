import model from "src/model";

export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      "apiVersion": "",
      "data": []
    }
  }
  object.getData = function getData() {
    return object.data ? object.data : [];
  }
  object.hasList = function hasList() {
    return object.getData().length > 0 ? true : false;
  }
  object.getList = function getList() {
    return object.getData().map((data) => model.node(data));
  }
  return object;
}

