import model from "src/model";

const LOG_TAG = 'account_bloc';
export default {
  syncTemplateList(onSuccess, onError) {
    window.logger.d(LOG_TAG, 'syncTemplateList');
    window.webapi.kickstart.getTemplateList(
      (data) => {
        if (null!=onSuccess) onSuccess(data);
      }, (error) => {
        window.logger.w(LOG_TAG, 'syncTemplateList', error);
        if (null!=onError) onError(error);
      }
    )
  },
  syncUserProfile(onSuccess, onError) {
    window.logger.d(LOG_TAG, 'syncUserProfile');
    window.webapi.profile.getUserProfile(
      (data) => {
        let dataObject = model.userProfile(data);
        window.store.commit('setProfile', dataObject );

        if (null!=onSuccess) onSuccess(dataObject);
      }, (error) => {
        window.logger.w(LOG_TAG, 'syncUserProfile', error);
        if (null!=onError) onError(error);
      }
    )
  },
  syncUserOrganisations(onSuccess, onError) {
    window.logger.d(LOG_TAG, 'syncUserOrganisations');
    window.webapi.organisation.getList(
      (data) => {
        let dataList = model.organisations(data).getList();
        window.store.commit('setOrganisations', dataList);

        window.logger.d(LOG_TAG, 'syncUserOrganisations', dataList.length);
        if (null!=onSuccess) onSuccess(dataList);
      }, (error) => {
        window.logger.w(LOG_TAG, 'syncUserOrganisations', error);
        if (null!=onError) onError(error);
      }
    )
  },
  syncUserContacts(onSuccess, onError) {
    window.logger.d(LOG_TAG, 'syncUserContacts');
    window.webapi.contact.getList(
      (data) => {
        let dataList = model.contacts(data).getList();
        window.store.commit('setContacts', dataList);

        window.logger.d(LOG_TAG, 'syncUserContacts', dataList.length, dataList);
        if (null!=onSuccess) onSuccess(dataList);
      }, (error) => {
        window.logger.w(LOG_TAG, 'syncUserContacts', error);
        if (null!=onError) onError(error);
      }
    )
  },
  syncUserNodes(onSuccess, onError) {
    window.logger.d(LOG_TAG, 'syncUserNodes');
    window.webapi.node.getList(
      (data) => {
        let dataList = model.nodes(data).getList();
        window.store.commit('setNodes', dataList);
        window.logger.d(LOG_TAG, 'syncUserNodes', dataList.length);
        if (null!=onSuccess) onSuccess(dataList);
      }, (error) => {
        window.logger.w(LOG_TAG, 'syncUserNodes', error);
        if (null!=onError) onError(error);
      }
    )
  },
  syncUserNetworks(onSuccess, onError) {
    window.logger.d(LOG_TAG, 'syncUserNetworks');
    window.webapi.network.getList(
      (data) => {
        let dataList = model.networks(data).getList();
        window.store.commit('setNetworks', dataList);
        window.logger.d(LOG_TAG, 'syncUserNetworks', dataList.length);
        if (null!=onSuccess) onSuccess(dataList);
      }, (error) => {
        window.logger.w(LOG_TAG, 'syncUserNetworks', error);
        if (null!=onError) onError(error);
      }
    )
  },
};
