import kickstart from "src/webapi/apis/kickstart"
import profile from "src/webapi/apis/profile"
import organisation from "src/webapi/apis/organisation"
import contact from "src/webapi/apis/contact"
import node from "src/webapi/apis/node"
import nodeinterface from "src/webapi/apis/nodeinterface"
import network from "src/webapi/apis/network"
export default {
  kickstart,
  profile,
  organisation,
  contact,
  node,
  nodeinterface,
  network
};
