import model from "src/model";

const LOG_TAG = 'update_bloc';
export default {
  updateNode(uuid, data, onSuccess, onError) {
    const dataPayload = {
      "name": data.name
    };
    window.logger.d(LOG_TAG, 'updateNode', dataPayload);
    window.webapi.node.update(
      uuid, dataPayload,
      (data) => {
        window.logger.d(LOG_TAG, 'syncUserNodes');
        window.bloc.account.syncUserNodes(
          (data) => {
            if (null!=onSuccess) onSuccess(data);
          }, (error) => {
            if (null!=onError) onError(error);
          }
        )
      }, (error) => {
        if (null!=onError) onError(error);
      }
    )

  },
  updateUserProfile(data, onSuccess, onError) {
    const dataPayload = {
      "first_name": data.firstName,
      "last_name": data.lastName,
      "jobtitle": data.jobTitle,
      "about": data.about,
    };
    window.logger.d(LOG_TAG, 'updateUserProfile', dataPayload);
    window.webapi.profile.updateUserProfile(
      dataPayload,
      (data) => {
        window.logger.d(LOG_TAG, 'syncUserProfile');
        window.bloc.account.syncUserProfile(
          (data) => {
            if (null!=onSuccess) onSuccess(data);
          }, (error) => {
            if (null!=onError) onError(error);
          }
        )
      }, (error) => {
        if (null!=onError) onError(error);
      }
    )

  },
  /**
   * 
   * @param {Object} data 
   * @param {string} data.fname First Name of Contact
   * @param {string} data.lname Last Name of Contact
   * @param {string} data.email Primary Email of Contact
   * @param {string} data.phone Primary Phone number of Contact
   * @param {Object} data.addr Primary Phone number of Contact
   * @param {ContactAddress} data.addr.physical Physical Address of Contact
   * @param {ContactAddress} data.addr.postal Postal Address of Contact
   * @param {SuccessCb} onSuccess 
   * @param {function(Error)} onError 
   */
  createContact(data, onSuccess, onError) {
    window.logger.d(LOG_TAG, 'createNewContact');

    const dataPayload = {
      "name": data.fname + " " + data.lname,
      "firstname": data.fname,
      "lastname": data.lname,

      "email": data.email,

      // Phone Information
      "homephone": data.phone,
      "mobilephone": data.phone,
      "businessphone": data.phone,

      // Address Information
      "physical_address": {
        "address_street_1": data.addr.physical.addr1,
        "address_street_2": data.addr.physical.addr2,
        "suburb": data.addr.physical.city,
        "city": data.addr.physical.city,
        "postcode": data.addr.physical.postal,
        "state_region": data.addr.physical.state,
        "country_code": data.addr.physical.country
      },
      "postal_address": {
        "address_street_1": data.addr.postal.addr1,
        "address_street_2": data.addr.postal.addr2,
        "suburb": data.addr.postal.city,
        "city": data.addr.postal.city,
        "postcode": data.addr.postal.postal,
        "state_region": data.addr.postal.state,
        "country_code": data.addr.postal.country
      },

      // Meta Data
      "iscustomer":1,
      "isstaff":0,
      "issupplier":0,
      "isbusiness":0,
      "nospam":1
    }
    
    window.webapi.contact.create(
      dataPayload,
      (data) => {
        // upon create success we refresh organization list
        window.bloc.account.syncUserContacts(
          () => {
            if (null!=onSuccess) onSuccess(data);
          }, (error) => {
            if (null!=onError) onError(error);
          }
        )
      }, (error) => {
        window.logger.w(LOG_TAG, 'createNewContact', error);
        if (null!=onError) onError(error);
      }
    )
  },
  createOrganisation(data, onSuccess, onError) {
    window.logger.d(LOG_TAG, 'createNewOrganisations', data);
    const dataPayload = {
      "name": data.name,
      "contact": {
        "uuid": data.contactUuid,
      },
      "notes": data.notes,
    };
    window.webapi.organisation.create(
      dataPayload,
      (data) => {
        // upon create success we refresh organization list
        window.bloc.account.syncUserOrganisations(
          () => {
            if (null!=onSuccess) onSuccess(data);
          }, (error) => {
            if (null!=onError) onError(error);
          }
        )
      }, (error) => {
        window.logger.w(LOG_TAG, 'createNewOrganisations', error);
        if (null!=onError) onError(error);
      }
    )
  },
  joinOrganisation(uuid, onSuccess, onError) {
    window.logger.d(LOG_TAG, 'joinNewOrganisation');

    window.webapi.organisation.requestJoin(
      uuid,
      (data) => {
        onSuccess(data);
      }, (error) => {
        onError(error);
      }
    )
  },
  deleteOrganisation(organisationUuid, onSuccess, onError) {
    window.logger.d(LOG_TAG, 'deleteOrganisation');
    window.webapi.organisation.delete(
      organisationUuid,
      (data) => {
        // upon create success we refresh organization list
        window.bloc.account.syncUserOrganisations(
          (data) => {
            if (null!=onSuccess) onSuccess(data);
          }, (error) => {
            if (null!=onError) onError(error);
          }
        )
      }, (error) => {
        window.logger.w(LOG_TAG, 'deleteOrganisation', error);
        if (null!=onError) onError(error);
      }
    )
  },
  createNodeOrder(data, onSuccess, onError) {
    window.logger.d(LOG_TAG, 'createNodeOrder');
    window.webapi.node.createOrder(
      data,
      (data) => {
        // upon create success we refresh list
        window.bloc.account.syncUserNodes(
          (data) => {
            if (null!=onSuccess) onSuccess(data);
          }, (error) => {
            if (null!=onError) onError(error);
          }
        )
      }, (error) => {
        if (null!=onError) onError(error);
      }
    )
  },
  createNetwork(data, onSuccess, onError) {
    window.logger.d(LOG_TAG, 'createNetwork');
    const dataPayload = {
      "name": data.name,
      "organisation": {
        "uuid": data.organisation
      }
    };
    window.webapi.network.createNetwork(
      dataPayload,
      (data) => {
        // upon create success we refresh list
        window.bloc.account.syncUserNetworks(
          (data) => {
            if (null!=onSuccess) onSuccess(data);
          }, (error) => {
            if (null!=onError) onError(error);
          }
        )
      }, (error) => {
        if (null!=onError) onError(error);
      }
    )
  },
  updateNetwork(data, onSuccess, onError) {
    window.logger.d(LOG_TAG, 'updateNetwork', data.uuid);
    const dataPayload = {
      "name": data.name,
    };
    window.webapi.network.update(
      data.uuid, dataPayload,
      (data) => {
        // upon create success we refresh list
        window.bloc.account.syncUserNetworks(
          (data) => {
            if (null!=onSuccess) onSuccess(data);
          }, (error) => {
            if (null!=onError) onError(error);
          }
        )
      }, (error) => {
        if (null!=onError) onError(error);
      }
    )
  },
};

/**
 * The callback function to call on an successful API request
 * 
 * @callback SuccessCb
 * @param {Object} data Data received from function
 * @returns {void}
 */

/**
 * @typedef ContactAddress
 * @property {string} addr1   Address Line 1
 * @property {string} addr2   Address Line 2
 * @property {string} city    City
 * @property {string} state   State, Province or Region
 * @property {string} postal  Postal or Zip Code
 * @property {string} country Country of Address
 */