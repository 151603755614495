import model from "src/model"

const state = {
  userProfile: model.userProfile(),
  contacts: [],
};

const getters = {
  allUsers: (state) => state.users,
  userProfile: (state) => state.userProfile
};

const actions = {
};

const mutations = {
  setProfile: (state, data) => (state.userProfile = data),
  setContacts: (state, data) => (state.contacts = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
  components: {
  }
};
