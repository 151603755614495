import apiHelper from "src/webapi/api_helper"

// target
const TARGET_API = 'contact';

// paths
const API_LIST = 'list';

// params

const LOG_TAG = 'contact';
export default {
  getList(onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, API_LIST);

    window.logger.d(LOG_TAG, 'getList', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  create(data, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API);

    window.logger.d(LOG_TAG, 'create', url);
    apiHelper.apiPost(url, data, onResponseCb, onErrorCb);
  }
}
