const state = {
  alerts: [
    // {
    //   display: true,
    //   type:"success",
    //   title:"Record Saved",
    //   message:"User Profile saved Successfully"
    // }
  ]
};

const mutations = {
  addAlert: (state, data) => (state.alerts.push(data)),
  removeAlert: (state, dataIndex) => (delete state.alerts[dataIndex]),
  removeAllAlerts: (state, data) => (state.alerts = [])
};

const getters = {
  allAlerts: (state) => state.alerts,
};

const actions = {
  removeAllAlerts() {
    store.commit('removeAllAlerts', );
  },
  addWarning(title,message) {
    let d = new Date()
    store.commit('addAlert', {
      visible: true,
      time: d.getTime(),
      type:'danger',
      title:message.title,
      message:message.message,
      icon: 'tim-icons icon-sound-wave',
      timeout: message.timeout ? message.timeout : 10000,
    });
  },
  addSuccess(title,message) {
    let d = new Date()
    store.commit('addAlert', {
      visible: true,
      time: d.getTime(),
      type:'success',
      title:message.title,
      message:message.message,
      icon:'tim-icons icon-check-2',
      timeout: message.timeout ? message.timeout : 10000,
    });
  },
  addInfo(title,message) {
    let d = new Date()
    store.commit('addAlert', {
      visible: true,
      time: d.getTime(),
      type:'info',
      title:message.title,
      message:message.message,
      icon: 'tim-icons icon-bulb-63',
      timeout: message.timeout ? message.timeout : 10000,
    });
  },
  addError(title,message) {
    let d = new Date()
    store.commit('addAlert', {
      visible: true,
      time: d.getTime(),
      type:'danger',
      title:message.title,
      message:message.message,
      icon: 'tim-icons icon-time-alarm',
      timeout: message.timeout ? message.timeout : 10000,
    });
  },
  /*
  addErrorAxios(title, response) {
    let d = new Date()
    store.commit('addAlert', {
      visible: true,
      time: d.getTime(),
      type:'danger',
      title:message.title,
      message:message.message,
      icon: 'tim-icons icon-time-alarm',
      timeout: message.timeout ? message.timeout : 10000,
    });
  }
  */
}

export default {
  state,
  getters,
  actions,
  mutations,
}

