export default function (copy) {
  let object;
  if (copy) {
    object = JSON.parse(JSON.stringify(copy));
  } else {
    object = {
      "apiVersion": "",
      "data": {
        "uuid": "",
        "active": false,
        "name": "",
        "first_name": "",
        "last_name": "",
        "email": "",
        "email_verified": 1,
        "picture": "",
        "jobtitle": "",
        "about": "",
        "activeOrganisation": null
      }
    };
  }

  //
  object.getData = function getData() {
    return object.data ? object.data : {};
  }
  object.getUuid = function getUuid() {
    return object.getData() ? object.getData().uuid : '';
  }
  object.getEmail = function getEmail() {
    return object.getData() ? object.getData().email : '';
  }
  object.getFirstName = function getFirstName() {
    return object.getData() ? object.getData().first_name : '';
  }
  object.getLastName = function getLastName() {
    return object.getData() ? object.getData().last_name : '';
  }
  object.formattedFullName = function formattedFullName() {
    return (object.getFirstName() && object.getLastName()) ? object.getFirstName() + ' ' + object.getLastName() : '';
  }
  object.getJobTitle = function getJobTitle() {
    return object.getData() ? object.getData().jobtitle : '';
  }
  object.getAbout = function getAbout() {
    return object.getData() ? object.getData().about : '';
  }
  object.getCompany = function getCompany() {
    return object.getData() ? (object.getData().activeOrganisation ? object.getData().activeOrganisation : null) : null;
  }
  object.hasCompany = function hasCompany() {
    return object.getCompany() ? object.getCompany().name !== "unspecified" : false;
  }
  object.getCompanyName = function getCompanyName() {
    return object.hasCompany() ? object.getCompany().name : '';
  }
  return object;
}

