import apiHelper from "../api_helper"

// target
const TARGET_API = 'userprofile';

// paths

// params

const LOG_TAG = 'profile';
export default {
  getUserProfile(onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API);

    window.logger.d(LOG_TAG, 'getUserProfile', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  updateUserProfile(data, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API);

    window.logger.d(LOG_TAG, 'updateUserProfile', url);
    apiHelper.apiPut(url, data, onResponseCb, onErrorCb);
  },
  sendVerificationEmail(onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API, "verify");
    window.logger.d(LOG_TAG, 'sendVerificationEmail', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  },
  switchUserProfileOrg(companyUuid, onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl(TARGET_API);

    const data = {
      "activeOrganisation": {
        "uuid": companyUuid,
      }
    };

    window.logger.d(LOG_TAG, 'updateUserProfileOrg', url);
    apiHelper.apiPut(url, data, onResponseCb, onErrorCb);
  },
  getCountryList(onResponseCb, onErrorCb) {
    let url = apiHelper.createUrl('country', 'list');
    window.logger.d(LOG_TAG, 'getCountries', url);
    apiHelper.apiGet(url, onResponseCb, onErrorCb);
  }
}
